<template>
    <div
        class="vehicl-map"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
    >
        <div class="map-con">
            <div class="functionalArea" :class="{'isLargeScreen': isLargeScreen}">
                <div class="station-list el_left" v-if="stationList.length>1">
                    <el-select
                        v-model="currentStationId"
                        placeholder="请选择"
                        @change="changeStation"
                    >
                        <el-option
                            v-for="item in stationList"
                            :key="item.station_id"
                            :label="item.name"
                            :value="item.station_id"
                        >
                        </el-option>
                    </el-select>
                </div>
                <el-amap-search-box
                    v-if="iconStatus"
                    class="search-box el_left"
                    :search-option="searchOption"
                    :on-search-result="onSearchResult"
                ></el-amap-search-box>
                <div v-if="!iconStatus" class="search-car-div el_left">
                    <el-input placeholder="工程名称/工程地址/车号/车牌号" v-model="searchInfo"></el-input>
                    <!-- <i @click="getLocation" class="iconfont iconsousuo1"></i> -->
                    <i @click="getLocation">搜索</i>
                </div>
                <div class="checkout-icon el_left">
                    <el-tooltip
                        v-show="iconStatus"
                        class="item"
                        effect="dark"
                        content="去检索工程与车辆信息"
                        placement="bottom"
                    >
                        <i @click="checkoutIcon" class="el-icon-map-location"></i>
                    </el-tooltip>
                    <el-tooltip
                        v-show="!iconStatus"
                        class="item"
                        effect="dark"
                        content="去检索地理位置信息"
                        placement="bottom"
                    >
                        <i v-show="!iconStatus" @click="checkoutIcon" class="el-icon-truck"></i>
                    </el-tooltip>
                </div>
                <div class="setting-top-box el_left" @click="setCarTime">
                    <span class="iconfont">&#xe672;</span>
                    <span>设置</span>
                </div>
                <div class="antline-btn el_left" @click="showAntLine">
                    <span class="iconfont icontiaodu"></span>
                    <span>蚂蚁线</span>
                </div>
            </div>
            <!-- 总设置 -->
            <div class="setting-window" v-show="settingWindowStatus">
                <div class="setting-window-top">
                    <p>定位频率设置</p>
                    <span class="triangle-up"></span>
                </div>
                <div class="setting-input">
                    <input v-model="setWholeTime" placeholder="请输入" type="text">
                    <span>秒</span>
                </div>
                <div class="setting-btn">
                    <el-button type="primary" size="mini" @click="setCarInterval(0)">
                        确定
                    </el-button>
                    <el-button plain size="mini" @click="settingWindowStatus = false">
                        取消
                    </el-button>
                </div>
            </div>
            <!-- 单独车辆设置 -->
            <div class="setting-window setting-window-two" v-show="settingWindowStatusTwo">
                <div class="setting-window-top">
                    <p>定位频率设置</p>
                    <span class="triangle-up"></span>
                </div>
                <div class="setting-input">
                    <input v-model="setWholeTime" placeholder="请输入" type="text">
                    <span>秒</span>
                </div>
                <div class="setting-btn">
                    <el-button @click="setCarInterval(0)" type="primary" size="mini">
                        确定
                    </el-button>
                    <el-button plain size="mini" @click="settingWindowStatusTwo = false">
                        取消
                    </el-button>
                </div>
            </div>
            <el-amap
                vid="amapDemo"
                :zoom="zoom"
                :center="mapCenter"
                class="amap-demo"
                :plugin="plugin"
                :amap-manager="amapManager"
                :events="mapEvents"
            >
                <!-- 点标记 -->
                <el-amap-marker
                    v-for="(marker, index) in markers"
                    :key="index"
                    :position="marker.position"
                    :size="marker.size"
                    :events="marker.events"
                    :content="marker.content"
                ></el-amap-marker>
                <!-- 工程地址 -->
                <el-amap-marker
                    v-for="(marker, index) in projectsAddressMarkers"
                    :key="`address${index}`"
                    :position="marker.position"
                    :icon="marker.icon"
                    :size="marker.size"
                    :events="marker.events"
                ></el-amap-marker>
                <!-- 工程地址信息窗体 -->
                <el-amap-info-window
                    :position="addressWindow.position"
                    :visible="addressWindow.visible"
                    :offset="addressWindow.offset"
                    :events="addressWindow.events"
                    :close-when-click-map="true"
                >
                    <projectAddressWindow></projectAddressWindow>
                </el-amap-info-window>
                <!-- 信息窗体 -->
                <el-amap-info-window
                    :position="windows.position"
                    :visible="windows.visible"
                    :offset="windows.offset"
                    :events="windows.events"
                    :close-when-click-map="true"
                >
                    <div class="car-info" v-if="carInfo">
                        <div class="icon iconfont iconshangjiantou1"></div>
                        <div class="info-header">
                            <div>
                                <span class="iconfont">&#xe6a3;</span>
                                <span class="plateNumber">{{ carInfo.plate_number }}</span>
                                <span class="trumpet">{{ carInfo.plate_small_number }}</span>
                                <!-- eslint-disable-next-line max-len -->
                                <span
                                    v-if="[6, 7, 73].indexOf(carInfo.status) > -1"
                                    class="transportation-status transportation-status-green"
                                >运输中</span>
                                <span
                                    v-else-if="[77, 8].indexOf(carInfo.status) > -1"
                                    class="transportation-status transportation-status-yellow"
                                >返场中</span>
                                <span v-else class="transportation-status transportation-status-red">未运输</span>
                            </div>
                            <div>
                                <span @click="setWinodShow" class="iconfont setting-car-window">&#xe672;</span>
                            </div>
                        </div>
                        <div class="info-detailed">
                            <template v-if="[6, 7, 73, 77, 8].indexOf(carInfo.status) > -1">
                                <div>
                                    <p>
                                        <span class="iconfont">&#xe6b7;</span>
                                        <span>司机姓名：{{ carInfo.driver_name }}</span>
                                    </p>
                                    <p style="text-align: right;">
                                        <span class="iconfont">&#xe6c7;</span>
                                        <span>电话：{{ carInfo.driver_phone }}</span>
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <span class="iconfont">&#xe693;</span>
                                        <span>发货单号：{{ carInfo.pcb_number }}</span>
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <span class="iconfont">&#xe67b;</span>
                                        <span>工地名称：{{ carInfo.project_name }}</span>
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <span class="iconfont">&#xe646;</span>
                                        <span>今日运输次数：{{ carInfo.cur_count }}</span>
                                    </p>
                                </div>
                                <template v-if="[77, 8].indexOf(carInfo.status) > -1">
                                    <div>
                                        <p>
                                            <span class="iconfont">&#xe69f;</span>
                                            <span>车辆距场站（KM）：{{ carInfo.to_station_distance }}</span>
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            <span class="iconfont">&#xe667;</span>
                                            <span>预计到达场站时间：{{ SecondToDate(carInfo.to_station_time) }}</span>
                                        </p>
                                    </div>
                                </template>
                                <template v-else>
                                    <div>
                                        <p>
                                            <span class="iconfont">&#xe69f;</span>
                                            <span>车辆距工地（KM）：{{ carInfo.to_project_distance }}</span>
                                        </p>
                                    </div>
                                    <div v-if="carInfo.status == 6">
                                        <p>
                                            <span class="iconfont">&#xe667;</span>
                                            <span>预计到达工地时间：{{ SecondToDate(carInfo.to_project_time) }}</span>
                                        </p>
                                    </div>
                                    <div v-else>
                                        <p>
                                            <span class="iconfont">&#xe667;</span>
                                            <span>到达工地时间：{{ carInfo.arrival_time }}</span>
                                        </p>
                                    </div>
                                </template>
                            </template>
                            <template v-else>
                                <div>
                                    <p>
                                        <span class="iconfont">&#xe6b7;</span>
                                        <span>司机姓名：{{ carInfo.driver_name }}</span>
                                    </p>
                                    <p style="text-align: right;">
                                        <span class="iconfont">&#xe6c7;</span>
                                        <span>电话：{{ carInfo.driver_phone }}</span>
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <span class="iconfont">&#xe693;</span>
                                        <span>发货单号：</span>
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <span class="iconfont">&#xe67b;</span>
                                        <span>工地名称：</span>
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <span class="iconfont">&#xe646;</span>
                                        <span>今日运输次数：{{ carInfo.cur_count }}</span>
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <span class="iconfont">&#xe69f;</span>
                                        <span>车辆距工地（KM）：</span>
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        <span class="iconfont">&#xe667;</span>
                                        <span>到达工地时间：</span>
                                    </p>
                                </div>
                            </template>
                        </div>
                    </div>
                </el-amap-info-window>
                <el-amap-circle
                    :key="`circle${index}`"
                    v-for="(circle, index) in projectCircles"
                    :center="circle.center"
                    :radius="circle.radius"
                    :stroke-color="circle.strokeColor"
                    :stroke-opacity="circle.strokeOpacity"
                    :stroke-weight="circle.strokeWeight"
                    :fill-color="circle.fillColor"
                    :fill-opacity="circle.fillOpacity"
                    :stroke-style="circle.strokeStyle"
                ></el-amap-circle>
                <el-amap-circle
                    :key="`circle${index}`"
                    v-for="(circle, index) in stationCircles"
                    :center="circle.center"
                    :radius="circle.radius"
                    :stroke-color="circle.strokeColor"
                    :stroke-opacity="circle.strokeOpacity"
                    :stroke-weight="circle.strokeWeight"
                    :fill-color="circle.fillColor"
                    :fill-opacity="circle.fillOpacity"
                    :stroke-style="circle.strokeStyle"
                ></el-amap-circle>
            </el-amap>
            <!-- 右侧工地、车辆筛选色块 -->
            <vehicleDispatch
                :projects-statistics="projectsStatistics"
                :car-statistics="carStatistics"
                :is-large-screen="isLargeScreen"
                @projectsClick="handleProjectsClick"
                @carClick="handleCarClick"
                :reset-active="timer"
            ></vehicleDispatch>
            <!-- 右侧功能面板 -->
            <!-- <actionsPanel></actionsPanel> -->
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { eventBus } from '@/common/eventBus.ts';
import util from '@/utils/util';
import vehicleDispatch from './vehicleDispatch';
import actionsPanel from './actionsPanel';
import projectAddressWindow from './projectAddressWindow';
import VueAMap from 'vue-amap';
const amapManager = new VueAMap.AMapManager();
VueAMap.initAMapApiLoader({
    key: 'b4007ecc143884e79eab2c515ede82cf',
    // eslint-disable-next-line max-len
    plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Geolocation', 'AMap.Geocoder', 'AMap.AMapManager', 'AMap.Marker', 'AMap.GeometryUtil'],
    v: '1.4.4',
});
export default {
    components: { actionsPanel, projectAddressWindow,vehicleDispatch },
    props: {
        isLargeScreen: {
            type: [Boolean],
            required: false, // 是否必传
        },
        pageType: {
            type: [Array, Object, String ],
            required: false, // 是否必传
        },
    },
    data() {
        const self = this;
        return {
            iconStatus: true,
            searchInfo: '',
            // 地图相关
            amapManager,
            accuratePosition: {},
            zoom: 13,
            mapCenter: [116.397428, 39.90923],
            lng: 0,
            lat: 0,
            loaded: false,
            settingWindowStatus: false,
            settingWindowStatusTwo: false,
            setWholeTime: 0,
            searchOption: {
                city: '全国',
                citylimit: true,
            },
            mapEvents: {
                init(o) {
                    if (self.isLargeScreen) {
                        o.setMapStyle('amap://styles/darkblue');
                    }

                },
            },
            saitchData: {},
            projectsAddressMarkers: [],
            projectsStatistics: {
                all: 0,
                notWork: 0,
                notFinished: 0,
                trans: 0,
            },
            // 工程地址统计
            carStatistics: {
                all: 0,
                notWork: 0,
                trans: 0,
                back: 0,
            },
            // 车辆统计
            windows: {
                position: [0.0, 0.0],
                visible: false,
                offset: [-10, -20],
                events: {
                },
            },
            // 工程地址信息窗体
            addressWindow: {
                position: [0.0, 0.0],
                visible: false,
                offset: [-10, -20],
                events: {
                },
            },
            switchData: {},
            groundimages: [
                {
                    // eslint-disable-next-line max-len
                    url: 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1590989238989&di=c6233e26d355c10a8f3d2f26e9ce5657&imgtype=0&src=http%3A%2F%2Fbpic.588ku.com%2Felement_pic%2F01%2F47%2F31%2F885743e52ebb6e4.jpg',
                    bounds: [[121.5273285, 31.21515044], [122.9273285, 32.31515044]],
                    clickable: true,
                    events: {
                        click: () => {
                            alert('aaa');
                        },
                    },
                },
            ],
            // 点标记
            markers: [],
            plugin: [{
                pName: 'Geolocation',
                enableHighAccuracy: true, // 是否使用高精度定位，默认:true
                timeout: 10, // 超过10秒后停止定位，默认：无穷大
                zoomToAccuracy: true,
            }],
            carPosition: {},
            carInfo: {},
            carStatus: {},
            // 场站数据
            stationData: {},
            activeProject: 1, // 选中工地
            oldProjects: [],
            activeCar: 1,
            oldCars: [],
            // 场站
            stationList: [],
            // 当前场站Index
            activeStation: 0,
            // 当前场站ID
            currentStationId: '',
            // 当天有任务的工地数据
            fenceData: [],
            // 地图对象
            mapObj: {},
            // 工地围栏
            projectEchoPolygon: [],
            // 工地圆圈
            projectCircles: [],
            // 场站围栏
            stationEchoPolygon: [],
            // 场站圆圈
            stationCircles: [],
            timer: 0,
            // 加载状态
            loading: false,
            // 是否拉取所有场站
            allStation: '',
        };
    },
    beforeDestroy() {
        // 清除事件总线eventBus， 不手动清除，它是一直会存在的，就会反复进入
        eventBus.$off('Monitoring');
        eventBus.$off('CoordinateMessage');
    },
    watch: {
        pageType(newVal) {
            if (newVal && newVal === 'all') {
                this.getAllStation();
            }
        },
        carPosition: {
            deep: true,
            immediate: true,
            handler: function (val) {
                this.markers.forEach(item => {
                    if (item.carName === val.car_license) {
                        item.position = [val.longitude, val.latitude];
                        let direction;
                        if (val.direction) {
                            direction = parseInt(Number(val.direction), 0);
                        } else {
                            direction = 0;
                        }
                        const this_car = this.$('.car' + item.plate_small_number);
                        if (this_car.length > 0) {
                            this.$('.car' + item.plate_small_number).find('p').css('transform', 'rotate(' + direction + 'deg)');
                        } else {
                            this.getContentHtml(item);
                        }
                    }
                });
                this.$forceUpdate();
            },
        },
        carStatus: {
            deep: true,
            immediate: true,
            handler: function (val) {
                let is_change = false;
                this.markers.forEach(item => {
                    if (item.carName === val.car_license && item.status !== val.car_status) {
                        let car_type = '';
                        let car_icon = '';
                        item.status = val.car_status;
                        if ([6, 7, 73].indexOf(item.status) > -1) {
                            car_icon = require('../../../assets/images/productionManagement/blue.png');
                            car_type = 'trans';
                        } else if ([77, 8].indexOf(item.status) > -1) {
                            car_icon = require('../../../assets/images/productionManagement/yellow.png');
                            car_type = 'back';
                        } else if ([1, 2, 21, 3, 31, 4, 5].indexOf(item.status) > -1) {
                            car_icon = require('../../../assets/images/productionManagement/red.png');
                            car_type = 'notWork';
                        }
                        if (car_type !== '' && item.type !== car_type) {
                            is_change = true;
                            item.icon = car_icon;
                            item.type = car_type;
                            this.$forceUpdate();
                        }
                    }
                });

                if (is_change) {
                    this.oldCars = JSON.parse(JSON.stringify(this.markers));
                    let notWork = 0; let trans = 0; let
                        back = 0;
                    this.markers.map(item => {
                        if ([6, 7, 73].indexOf(item.status) > -1) {
                            trans += 1;
                        } else if ([77, 8].indexOf(item.status) > -1) {
                            back += 1;
                        } else if ([1, 2, 21, 3, 31, 4, 5].indexOf(item.status) > -1) {
                            notWork += 1;
                        }
                    });
                    this.carStatistics.notWork = notWork;
                    this.carStatistics.trans = trans;
                    this.carStatistics.back = back;
                }
            },
        },
    },
    mounted() {
        eventBus.$on('Monitoring', data => {
            this.carStatus = JSON.parse(data);
        });
        eventBus.$on('CoordinateMessage', data => {
            this.carPosition = JSON.parse(data);
        });
        const searchBoxWrapper = document.getElementsByClassName('search-box-wrapper')[0];
        const searchInput = searchBoxWrapper.getElementsByTagName('input')[0];
        searchInput.setAttribute('placeholder', '地图位置');
        // 是否拉取所有场站
        this.allStation = this.$queryString(this.$route.meta.TableCode, 'type');

        if (typeof this.type === 'undefined') {
            setTimeout(() => {
                this.mapObj = amapManager.getMap();
                if (this.allStation === 'all'|| this.pageType==='all') {
                    this.getAllStation();
                } else {
                // 拉取用户场站
                    this.getStationUser();
                }
            }, 1000);
        }
    },
    created() {
    },
    methods: {
        // 切换icon
        checkoutIcon() {
            this.iconStatus = !this.iconStatus;
            this.searchInfo = '';
            if (this.iconStatus) {
                setTimeout(() => {
                    const searchBoxWrapper = document.getElementsByClassName('search-box-wrapper')[0];
                    const searchInput = searchBoxWrapper.getElementsByTagName('input')[0];
                    searchInput.setAttribute('placeholder', '地图位置');
                }, 50);
            }
        },
        // 秒转天
        SecondToDate(msd) {
            let time = msd;
            if (msd) {
                if (msd > 3600) {
                    time = '一小时以上';
                } else if (msd <= 3600 && msd >= 60 ) {
                    time = '大约 ' + parseInt(time / 60.0, 0) + '分钟 以后';
                } else {
                    time = '一分钟以内';
                }
            } else {
                msd = '';
            }
            return time;
        },
        // 设置车辆时间
        setCarTime() {
            this.settingWindowStatus = true;
            this.getCarInterval(0);
        },
        // 点标记
        switchWindow(tab, item, position) {
            this.loading = true;
            this.switchData = item;
            this.windows.visible = false;
            this.windows.position = position;
            const plate_number = item.plate_number || item.carName;
            this.getVehicleNo(plate_number, tab);
        },
        // 显示弹窗
        setWinodShow(event) {
            const setting = document.getElementsByClassName('setting-window-two')[0];
            setting.style.left = event.clientX - 200 + 'px';
            setting.style.top = event.clientY - 180 + 'px';
            this.getCarInterval();
            this.settingWindowStatusTwo = true;
        },
        // 搜索
        onSearchResult(pois) {
            if (pois.length > 0) {
                this.mapCenter = [pois[0].lng, pois[0].lat];
                this.zoom = 15;
            }
        },
        // 加载地图数据
        reLoadData() {
            this.getProjectStatus();
            this.getLocation();
        },
        // 获取车辆位置配置
        getCarInterval(status) {
            const setStatus = this.carInfo.plate_number ? this.carInfo.plate_number : status;
            this.$axios
                .get(`/interfaceApi/logistics/car_manager/get_car_interval?car_license=${setStatus}`)
                .then(res => {
                    this.setWholeTime = res;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 拉取车辆位置列表
        getLocation() {
            const carType = 0;
            this.$axios
                // eslint-disable-next-line max-len
                .get(`/interfaceApi/production/vehicleinfo/get_location?stationId=${this.currentStationId}&carType=${carType}&keywords=${this.searchInfo}`)
                .then(res => {
                    const markersArr = [];
                    this.markers = [];
                    if (res.length === 1) {
                        const newCenter = [res[0].longitude, res[0].latitude];
                        this.mapCenter = newCenter;
                        this.zoom = 13;
                    }
                    this.carStatistics = {
                        all: 0,
                        notWork: 0,
                        trans: 0,
                        back: 0,
                    };
                    res.forEach((item, index) => {
                        if (item.status !== 9) {
                            let markersObj = {};
                            if (item.longitude && item.latitude) {
                                // 车辆信息
                                markersObj = {
                                    carName: item.plate_number,
                                    plate_small_number: item.plate_small_number,
                                    position: [item.longitude, item.latitude],
                                    numberOffset: [],
                                    content: this.getContentHtml(item),
                                    icon: require('../../../assets/images/productionManagement/red.png'),
                                    events: {
                                        click: () => {
                                            this.switchWindow(index, item, markersObj.position);
                                        },
                                    },
                                    visible: false,
                                    type: '',
                                    status: item.status,
                                };
                                if ([6, 7, 73].indexOf(item.status) > -1) {
                                    markersObj.type = 'trans';
                                } else if ([77, 8].indexOf(item.status) > -1) {
                                    markersObj.type = 'back';
                                } else if ([1, 2, 21, 3, 31, 4, 5].indexOf(item.status) > -1) {
                                    markersObj.type = 'notWork';
                                }
                                markersArr.push(markersObj);
                            }
                            if ([6, 7, 73].indexOf(item.status) > -1) {
                                this.carStatistics.trans += 1;
                            } else if ([77, 8].indexOf(item.status) > -1) {
                                this.carStatistics.back += 1;
                            } else if ([1, 2, 21, 3, 31, 4, 5].indexOf(item.status) > -1) {
                                this.carStatistics.notWork += 1;
                            }
                        }
                    });
                    this.oldCars = JSON.parse(JSON.stringify(markersArr));
                    this.markers = markersArr;
                    this.carStatistics.all = res.length;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        // 生成车辆icon点标记
        getContentHtml(item) {
            let direction;
            let Shtml = '';
            let icon = '';
            const p0 = [item.longitude, item.latitude];
            const fenceArr = [];
            const temp = this.stationList[0].fence_point.split('|');
            temp.map(item => {
                const arr = [];
                const arr2 = item.split(',');
                arr.push(arr2[0], arr2[1]);
                fenceArr.push(arr);
            });
            // 判断 p0 是否在 p1-p2-p3 围成的封闭区域内
            const inRing = AMap.GeometryUtil.isPointInRing(p0, fenceArr);
            if (item.direction) {
                direction = parseInt(item.direction, 0);
            } else {
                direction = 0;
            }
            if ([6, 7, 73].indexOf(item.status) > -1) {
                if (inRing) {
                    icon = require('../../../assets/images/productionManagement/blue.png');
                } else {
                    icon = require('../../../assets/images/productionManagement/blue.gif');
                }

            } else if ([77, 8].indexOf(item.status) > -1) {
                if (inRing) {
                    icon = require('../../../assets/images/productionManagement/yellow.png');
                } else {
                    icon = require('../../../assets/images/productionManagement/yellow.gif');
                }

            } else if ([1, 2, 21, 3, 31, 4, 5].indexOf(item.status) > -1) {
                if (inRing) {
                    icon = require('../../../assets/images/productionManagement/red.png');
                } else {
                    icon = require('../../../assets/images/productionManagement/red.gif');
                }
            }
            Shtml += '<div class="car' + item.plate_small_number + '">';
            if (direction === 90 || direction === 270) {
                Shtml += '<p class="amap-marker-icon" style="height:32px; transform:rotate(' + direction + 'deg);">';
            } else {
                Shtml += '<p class="amap-marker-icon" style="transform:rotate(' + direction + 'deg);">';
            }
            if (!inRing) {
                Shtml += '<img src=' + icon + ' style="width:28px;height:45px;">';
            } else {
                Shtml += '<img src=' + icon + '>';
            }
            Shtml += '</p>';
            Shtml += '<p class="amap-marker-label">' + item.plate_small_number + '</p>';
            Shtml += '</div>';
            return Shtml;
        },
        // 获取车辆信息
        getVehicleNo(carName) {
            this.$axios
                .get(`/interfaceApi/production/vehicleinfo/get_vehicle_no?plate_number=${carName}`)
                .then(res => {
                    this.carInfo = res;
                    this.windows.visible = true;
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error);
                });
        },
        // 设置车辆回写时间间隔
        setCarInterval(status) {
            const setStatus = this.carInfo.plate_number ? this.carInfo.plate_number : status;
            const regPos = /^[0-9]+.?[0-9]*$/;
            const time = Number(this.setWholeTime);
            if (regPos.test(time)) {
                this.$axios
                    .put(`/interfaceApi/logistics/car_manager/set_car_interval/${setStatus}/${time}`)
                    .then(() => {
                        this.$message.success('操作成功');
                        this.settingWindowStatus = false;
                        this.settingWindowStatusTwo = false;
                        this.setWholeTime = 0;
                        this.setStatus = 0;
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            } else {
                this.$message.error('请输入正确的时间');
            }
        },
        // 获取当天有任务的工地
        getProjectStatus() {
            const currentDate = util.currentTimeFormat('yyyy-MM-dd');
            this.$axios
                .get('/interfaceApi/production/producttask/get_project_status?date=' + currentDate + '&stationCode=' + this.currentStationId)
                .then(res => {
                    if (res) {
                        // // 测试-----
                        // const obj = {
                        //     project_code: 'string',
                        //     project_name: 'string',
                        //     project_address: 'string',
                        //     project_longitude: '116.397428',
                        //     project_latitude: '39.90923',
                        //     fence_point: '',
                        //     status: 0,
                        // };
                        // res.push(obj);
                        // this.addressWindow.position = ['116.397428', '39.90923'];
                        // this.$nextTick(() => {
                        //     this.addressWindow.visible = true;
                        // });
                        this.addProjectFence(res);
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 添加所有工地marker和围栏数据
        addProjectFence(res) {
            let all = 0; let notWork = 0; let notFinished = 0; let trans = 0;
            this.fenceData = [];
            this.projectsAddressMarkers = [];
            // 清空工地围栏
            if (this.projectEchoPolygon.length > 0) {
                this.mapObj.remove(this.projectEchoPolygon);
            }
            this.projectCircles = [];
            // 工地按状态分类
            res.map(item => {
                item.longitude = item.project_longitude;
                item.latitude = item.project_latitude;
                if (item.project_longitude && item.project_latitude) {
                    const markersObj = {
                        position: [item.project_longitude, item.project_latitude],
                        icon: require('../../../assets/images/productionManagement/flag_blue.png'),
                        events: {
                            click: () => {
                                // this.showProjectPop(markersObj.position,item);
                            },
                        },
                        visible: false,
                        status: item.status,
                        type: '',
                    };
                    if (item.status === 1) {
                        markersObj.icon = require('../../../assets/images/productionManagement/flag_red.png');
                        markersObj.type = 'notWork';
                        item.fenceType = 'notWork';
                    } else if (item.status === 3) {
                        markersObj.icon = require('../../../assets/images/productionManagement/flag_green.png');
                        markersObj.type = 'trans';
                        item.fenceType = 'trans';
                    } else {
                        markersObj.type = 'notFinished';
                        item.fenceType = 'notFinished';
                    }
                    this.projectsAddressMarkers.push(markersObj);
                    item.longitude = item.project_longitude;
                    item.latitude = item.project_latitude;
                    this.fenceData.push(item);
                }
                if (item.status === 1) {
                    notWork += 1;
                } else if (item.status === 3) {
                    trans += 1;
                } else {
                    notFinished += 1;
                }
            });
            this.oldProjects = JSON.parse(JSON.stringify(this.projectsAddressMarkers));
            all = res.length;
            this.projectsStatistics.all = all;
            this.projectsStatistics.notWork = notWork;
            this.projectsStatistics.notFinished = notFinished;
            this.projectsStatistics.trans = trans;
            // 所有工地画围栏
            this.drawStationFence(res, 'project');
        },
        // 展示蚂蚁线
        showAntLine() {
            let stationCodeStr = '';
            if (this.stationList.length > 0) {
                stationCodeStr = this.stationList[this.activeStation].station_id;
            }
            this.$toast({
                title: false,
                type: 'eject',
                t_url: 'productionManagement/logisticsMonitoring/antLine',
                viewPosition: 'view',
                extr: { closeBtn: {}, stationCode: stationCodeStr },
            });
        },
        // 拉取用户关联场站
        getStationUser() {
            const Uid = this.$takeTokenParameters('Uid');
            this.$axios
                .get('/interfaceApi/baseinfo/stationmanger/get_station_user?userId=' + Uid)
                .then(res => {
                    if (res.length > 0) {
                        this.stationList = res;
                        this.currentStationId = res[0].station_id;
                        this.reLoadData();
                        this.mapCenter = [res[0].longitude, res[0].latitude];
                        this.drawStationFence([res[0]], 'station');
                    }
                })
                .catch(error => {
                    this.$message.error(error.TypeError);
                });
        },
        // 拉取所有场站
        getAllStation() {
            const CompanyId = this.$takeTokenParameters('CompanyId');
            const BranchId = this.$takeTokenParameters('BranchId');
            this.$axios
                .get('/interfaceApi/baseinfo/stationmanger/get_station_list?companyId=' + CompanyId + '&branchId=' + BranchId)
                .then(res => {
                    if (res.length > 0) {
                        this.stationList = res;
                        this.currentStationId = res[0].station_id;
                        this.reLoadData();
                        this.mapCenter = [res[0].longitude, res[0].latitude];
                        this.drawStationFence([res[0]], 'station');
                    }
                })
                .catch(error => {
                    this.$message.error(error.TypeError);
                });
        },
        // 顶部场站筛选
        changeStation() {
            this.$emit('siloSwitchingStation', this.currentStationId);
            let selectStation = {};
            this.stationList.forEach((item, index) => {
                if (item.station_id === this.currentStationId) {
                    selectStation = item;
                    this.activeStation = index;
                }
            });
            this.mapCenter = [selectStation.longitude, selectStation.latitude];
            this.reLoadData();
            // 画当前场站围栏
            if (this.stationEchoPolygon.length > 0) {
                this.mapObj.remove(this.stationEchoPolygon);
            }
            this.stationCircles = [];
            this.timer = new Date().getTime();
            this.drawStationFence([selectStation], 'station');
        },
        // 画围栏或一公里圆圈
        drawStationFence(data, type) {
            if (data) {
                data.map(item => {
                    if (item.fence_point) {
                        const arr = item.fence_point.split('|');
                        const polygonPath = [];
                        arr.map(item => {
                            const lngLat = item.split(',');
                            // eslint-disable-next-line no-undef
                            const obj = new AMap.LngLat(lngLat[0], lngLat[1]);
                            polygonPath.push(obj);
                        });
                        // 创建面覆盖物
                        // eslint-disable-next-line no-undef
                        const echoPolygon = new AMap.Polygon({
                            fillColor: '#00b0ff',
                            strokeColor: '#80d8ff',
                            path: polygonPath,
                            fillOpacity: 0.3,
                            strokeWeight: 2,
                        });
                        if (type === 'project') {
                            this.projectEchoPolygon.push(echoPolygon);
                        } else {
                            this.stationEchoPolygon.push(echoPolygon);
                        }
                        const mapObj = amapManager.getMap();
                        mapObj.add([echoPolygon]);
                    } else if (item.longitude && item.latitude) {
                        if (type === 'project') {
                            this.projectCircles.push(
                                {
                                    center: [item.longitude, item.latitude ],
                                    radius: 1000,
                                    strokeColor: '#e87f8d', // 线条颜色
                                    strokeOpacity: '0.8', // 轮廓线透明度
                                    strokeWeight: '2', // 轮廓线宽度
                                    fillColor: '#e87f8d', // 圆形填充颜色
                                    fillOpacity: 0.3, // 圆形填充透明度
                                    strokeStyle: 'dashed', // 轮廓线样式，实线:solid，虚线:dashed
                                },
                            );
                        } else {
                            this.stationCircles.push(
                                {
                                    center: [item.longitude, item.latitude ],
                                    radius: 1000,
                                    strokeColor: '#e87f8d', // 线条颜色
                                    strokeOpacity: '0.8', // 轮廓线透明度
                                    strokeWeight: '2', // 轮廓线宽度
                                    fillColor: '#e87f8d', // 圆形填充颜色
                                    fillOpacity: 0.3, // 圆形填充透明度
                                    strokeStyle: 'dashed', // 轮廓线样式，实线:solid，虚线:dashed
                                },
                            );
                        }
                    }
                });
            }
        },
        // 点击右侧工地筛选
        handleProjectsClick(num) {
            // 选中的工地类型
            this.activeProject = num;
            let Markers = [];
            this.projectsAddressMarkers = this.oldProjects;
            // 清空工地围栏
            if (this.projectEchoPolygon.length > 0) {
                this.mapObj.remove(this.projectEchoPolygon);
            }
            this.projectCircles = [];
            let fenceData = [];
            switch (this.activeProject) {
                case 2:
                    this.projectsAddressMarkers.map(item => {
                        if (item.type === 'notwork') {
                            Markers.push(item);
                        }
                    });
                    this.fenceData.map(item => {
                        if (item.fenceType === 'notwork') {
                            fenceData.push(item);
                        }
                    });
                    break;
                case 3:
                    this.projectsAddressMarkers.map(item => {
                        if (item.type === 'notFinished') {
                            Markers.push(item);
                        }
                    });
                    this.fenceData.map(item => {
                        if (item.fenceType === 'notFinished') {
                            fenceData.push(item);
                        }
                    });
                    break;
                case 4:
                    this.projectsAddressMarkers.map(item => {
                        if (item.type === 'trans') {
                            Markers.push(item);
                        }
                    });
                    this.fenceData.map(item => {
                        if (item.fenceType === 'trans') {
                            fenceData.push(item);
                        }
                    });
                    break;
                default:
                    // 所有工地
                    fenceData = this.fenceData;
                    Markers = this.projectsAddressMarkers;
            }
            // 工地围栏
            this.drawStationFence(fenceData, 'project');
            this.projectsAddressMarkers = Markers;
        },
        // 点击右侧车辆筛选
        handleCarClick(num) {
            let data = [];
            this.markers = [];
            switch (num) {
                case 2:
                    this.oldCars.map(item => {
                        if (item.type === 'trans') {
                            data.push(item);
                        }
                    });
                    break;
                case 3:
                    this.oldCars.map(item => {
                        if (item.type === 'back') {
                            data.push(item);
                        }
                    });
                    break;
                case 4:
                    this.oldCars.map(item => {
                        if (item.type === 'notWork') {
                            data.push(item);
                        }
                    });
                    break;
                default:
                    data = this.oldCars;
            }
            data.map((item, index) => {
                item.events = {
                    click: () => {
                        this.switchWindow(index, item, item.position);
                    },
                };
            });
            this.markers = data;
        },
    },

};
</script>
<style lang="stylus">
@import "./../css/vehicleLogisticsMonitoring.styl";
</style>
